<template>
  <div class="ben-cai-gan-mu white">
    <div class="head">
      <div class="zen-cai">
        <img
          :src="require('../assets/img/b-zen-cai.png')"
          width="100%"
          height="100%"
        />
      </div>
      <div class="rules f-13 text-center black" @click="toPage('bRules')">
        活动规则
      </div>
    </div>
    <template v-if="isJoin">
      <div class="title">
        <div class="f-17 text-center m-b-10" style="color: #f9e6ac">已预约</div>
        <div class="title-big text-center">
          <div class="title-big-icon">
            <img
              :src="require('../assets/img/b-title.png')"
              width="100%"
              height="100%"
            />
            <div class="yu-icon">
              <img
                :src="require('../assets/img/b-yu-icon.png')"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
        <div class="text-center m-t-30">
          <span class="title-heading f-17 white">
            数字藏品盲盒限量首发预约开启
          </span>
        </div>
        <div class="m-t-40 text-center f-15 white ju-zhong">
          <span style="opacity: 0.5">已预约人数</span>
          <span class="f-18">&nbsp;{{ total }}&nbsp;</span>
        </div>
        <div
          class="btn text-center f-25 bold white zheng-shi"
          v-if="status === 1"
        >
          已预约
        </div>
        <template v-else-if="status === 2">
          <div
            class="btn text-center f-25 bold white zheng-shi"
            @click="noBuy"
            style="filter: brightness(0.5)"
          >
            立即抢购
          </div>
        </template>
        <template v-else-if="status === 3">
          <div
            class="btn text-center f-25 bold white zheng-shi"
            @click="downApp"
          >
            下载APP参与抢购
          </div>
          <div class="text-center" v-if="period === 1">
            发售阶段：首轮空投发售
          </div>
          <div class="text-center" v-else-if="period === 2">
            发售阶段：第二轮优先发售
          </div>
          <div class="text-center" v-else>发售阶段：第三轮公开发售</div>
        </template>
        <div class="btn text-center f-25 bold white zheng-shi" v-else>
          已结束
        </div>
      </div>
      <div class="z-brand">
        <div class="f-16 text-center" v-if="status === 1">
          距预约结束还有：{{ showTime2 }}
        </div>
        <div class="f-16 text-center" v-else-if="status === 2">
          距抢购开始还有：{{ buyTime }}
        </div>
        <div class="f-16 text-center" v-else-if="status === 3">
          距抢购结束还有：{{ buyTime }}
        </div>
        <div class="brand white">
          <div class="f-13 m-b-10 brand-title">
            <span class="text-center">IP授权方</span>
            <span class="text-center p-l-30" style="box-sizing: border-box"
              >战略合作</span
            >
          </div>
          <div class="logo">
            <img
              :src="require('../assets/img/b-logo.png')"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        <!-- 要显示的话，下面一个大盒子的margin-top改一下高度 -->
        <div class="ck_media">
          <div class="tl_ck_media">知名媒体报道</div>
          <div class="lok_ck_media" @click="toPath">点击查看</div>
        </div>
      </div>
      <div class="has">
        <div class="has-one" :style="'background-image: url(' + Qimg + 'b-has-one-bg.gif)'">
          <div class="mask">
            <img
              :src="require('../assets/img/b-mask.png')"
              width="100%"
              height="100%"
            />
          </div>
          <div style="position: relative; z-index: 2">
            <div class="has-one-title f-13 m-b-20">
              <span>您已拥有的艾草数:</span>
              <span v-if="showTime3">集艾草倒计时:{{ showTime3 }}</span>
              <span v-else>集草活动已结束</span>
            </div>
            <div class="title-number">{{ grass }}</div>
            <div class="timeline m-t-20">
              <div class="timeline-left m-t-25">
                <div class="m-b-20">
                  <div class="bold m-b-5 f-11">2022.2.28 11:00</div>
                  <div class="f-11">开启预约和集草活动</div>
                </div>
                <div class="m-b-20">
                  <div class="bold m-b-5 f-11">2022.3.8 15:00</div>
                  <div class="f-11">开启首轮空投发售</div>
                </div>
                <div class="m-b-20">
                  <div class="bold m-b-5 f-11">2022.3.11 15:00</div>
                  <div class="f-11">结束集草活动预约</div>
                </div>
              </div>
              <div class="timeline-right">
                <div class="m-b-20">
                  <div class="bold m-b-5 f-11 text-right">2022.3.11 20:00</div>
                  <div class="f-11 text-right">公布集草活动中签名单</div>
                </div>
                <div class="m-b-20">
                  <div class="bold m-b-5 f-11 text-right">2022 3.12 15:00</div>
                  <div class="f-11 text-right">第二轮优先发售</div>
                </div>
                <div class="m-b-20">
                  <div class="bold m-b-5 f-11 text-right">2022.3.16 15:00</div>
                  <div class="f-11 text-right">第三轮公开发售</div>
                </div>
              </div>
            </div>
            <div
              class="has-one-btn black f-18 text-center"
              @click="toPage('benCaoShare')"
            >
              邀请好友参与预约
            </div>
            <div class="has-one-des text-center" style="color: #d3c7a1">
              <template v-if="showTime3">
                <span class="f-12" style="opacity: 0.7">拥有艾草即可参与</span
                ><br />
                <span class="f-12" style="opacity: 0.7"
                  >「本草纲目数字藏品盲盒优先购买资格」<span class="bold"
                    >抽签摇号</span
                  ></span
                ><br />
                <span class="bold f-13">本次邀请好友集草无数量上限</span><br />
                <span class="f-12 bold" style="opacity: 0.7"
                  >艾草数越多，用户中签概率越高</span
                >
              </template>
              <template v-else>
                <span class="f-12" style="opacity: 0.7">
                  集草抽签活动已结束<br />
                  继续邀请新用户预约可获得元石奖励
                </span>
                <div
                  class="bold f-12 text-center m-t-10"
                  style="color: #d3c7a1"
                >
                  艾草中签结果请下载APP查看
                </div>
                <div
                  class="bold f-12 text-center"
                  @click="downApp"
                  style="
                    color: #d3c7a1;
                    text-decoration: underline;
                    opacity: 0.7;
                  "
                >
                  点击此处跳转下载页面
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="f-11 m-t-25 m-b-40 text-center zhu" style="opacity: 0.6">
          注：我们不鼓励虚假刷单，如有暴力刷单，我们将予以封禁处理。
          <template v-if="showTime3">
            <br />
            邀请新用户参与可获得2株艾草和元石奖励
            <br />
            邀请老用户参与可获得1株艾草，无元石奖励
          </template>
        </div>
        <div class="has-two m-b-30">
          <div class="text-center f-26 m-b-30 has-two-title bold">小镇公告</div>
          <div class="task m-b-10">
            <div class="f-12 black task-left">
              <div class="m-b-5">本次藏品铸造将使用用户信息参与铸造</div>
              <div>开抢前需完善生日信息</div>
            </div>
            <div class="task-right">
              <template v-if="user.baZi">
                <van-icon name="success" class="green" />
                <span class="black f-12">已完成</span>
              </template>
              <span
                class="task-right-btn f-12 black text-center"
                v-else
                @click="toPage('user')"
                >去完成</span
              >
            </div>
          </div>
          <div class="task m-b-10">
            <div class="f-12 black task-left">
              <div class="m-b-5">积极响应国家相关法律要求</div>
              <div>用户获得数字藏品前必须完成实名认证</div>
            </div>
            <div class="task-right">
              <template v-if="user.isAttested">
                <van-icon name="success" class="green" />
                <span class="black f-12">已完成</span>
              </template>
              <span
                class="task-right-btn f-12 black text-center"
                v-else
                @click="toPage('attest')"
                >去完成</span
              >
            </div>
          </div>
          <!--          <div class="task m-b-25">-->
          <!--            <div class="f-12 black task-left">-->
          <!--              <div class="m-b-5">首次加入轻松小镇并在群聊内发对应口令</div>-->
          <!--              <div>可获得元石奖励</div>-->
          <!--            </div>-->
          <!--            <div class="task-right">-->
          <!--              <template v-if="user.hasGroup">-->
          <!--                <van-icon name="success" class="green" />-->
          <!--                <span class="black f-12">已完成</span>-->
          <!--              </template>-->
          <!--              <span-->
          <!--                class="task-right-btn f-12 black text-center"-->
          <!--                v-else-->
          <!--                @click="addGroup"-->
          <!--                >去完成</span-->
          <!--              >-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="text-center">
            <div class="has-two-group">
              <!-- <img
                :src="require('../assets/img/kefu.png')"
                width="100%"
                height="100%"
              /> -->
              <vue-qr :text="qrcode" :margin="5" :size="105"></vue-qr>
            </div>
          </div>
          <div class="has-two-wrap text-center black f-12">
            加入共创社群<br />
            第一时间了解小镇资讯
          </div>
        </div>
        <div class="des m-b-40">
          <div class="des-wrap">
            <div class="f-15 wrap-bg text-center des-wrap-title m-b-20 bold">
              我国首个中医药古籍数字藏品
            </div>
            <div class="f-12 hang-jian-ju m-b-25">
              《本草纲目》是李时珍耗尽三十年心血修撰的药物巨典。全书52卷、共190余万字，记载1892种药物，收纳11096个药方、1109幅药图，集明代以前本草学大成，且首次采用纲目体为药物分类，开创了历代本草著作之先河，在我国中医药文明发展中有着不可替代的独特贡献。
            </div>
            <div class="f-12 hang-jian-ju">
              本数字藏品是我国<span class="yellow bold"
                >首个中医药古籍数字藏品</span
              >
              ，堪称数字藏品发行里程碑，仅限量发行10000枚，永不增发。
            </div>
            <van-divider
              dashed
              :style="{ borderColor: '#907C5A', marginBottom: '25px' }"
            />
            <div class="f-15 wrap-bg text-center des-wrap-title m-b-20 bold">
              创新性采用用户共创铸造玩法
            </div>
            <div class="f-12 hang-jian-ju m-b-25">
              本次数字藏品铸造创新地采用“
              <span class="yellow bold"
                >用户参与、算法生成、反向铸造、完全随机</span
              >
              ”新玩法，分别发行“金木水火土”五种属性版本，每个版本各发行2000枚，通过盲盒形式随机分配属性。本次藏品用户需提供个人生辰信息参与藏品共创铸造，最终决定所获藏品“十二生肖”元素属性以及分配数量，
              <span class="yellow bold"
                >最终藏品稀缺性由参与用户共同铸造决定。</span
              >
            </div>
            <van-divider
              dashed
              :style="{ borderColor: '#907C5A', marginBottom: '25px' }"
            />
            <div class="f-15 wrap-bg text-center des-wrap-title m-b-20 bold">
              中医科学院信息所合作授权发布
            </div>
            <div class="f-12 hang-jian-ju m-b-25">
              中国中医科学院中医药信息研究所是中国唯一从事中医药信息研究与教学的部属研究院所，是文化部授予的“国家级古籍重点保护单位”、“全国中医行业古籍保护中心”、“国家级古籍修复中心”，现有馆藏中医古籍10万余册，其中包括中医古籍5589种，历代版本6556个，约占存世中医古籍品种的45%。
            </div>
            <div class="f-12 hang-jian-ju">
              本次发行的金陵版《本草纲目》为中医科学院信息所珍藏文物，已入选
              <span class="yellow bold"
                >第三批《国家珍贵古籍名录》和“联合国世界记忆遗产名录”</span
              >
              ，是具有纪念意义的珍贵文物遗存。
            </div>
            <van-divider
              dashed
              :style="{ borderColor: '#907C5A', marginBottom: '25px' }"
            />
            <div class="f-15 wrap-bg text-center des-wrap-title m-b-20 bold">
              源自家族自编版本高度体现原意
            </div>
            <div class="f-12 hang-jian-ju m-b-25">
              金陵版《本草纲目》是<span class="yellow bold"
                >目前流传于世的各版《本草纲目》的最早版本</span
              >
              ，也是迄今唯一完全由李时珍家族自编本刻版而成的版本，高度体现李时珍的原意。<br />
              经过400余年岁月洗礼，其内核历久弥新，依旧是新时代中医文化发展的重要思想源泉。
            </div>
            <van-divider
              dashed
              :style="{ borderColor: '#907C5A', marginBottom: '25px' }"
            />
            <div class="f-15 wrap-bg text-center des-wrap-title m-b-20 bold">
              领先的区块链技术保障安全可信
            </div>
            <div class="f-12 hang-jian-ju m-b-25">
              「本草纲目·金陵版」数字藏品基于头部互联网企业区块链技术解决方案发行，保障每个藏品在链上都拥有唯一标识，不可篡改，安全可靠，永久保存，上线后将开放限制性亲友转赠功能（详情请见活动规则）。
            </div>
          </div>
        </div>
        <div class="text-center">
          <div class="has-two-grou">
            <img
              :src="require('../assets/img/kefu.png')"
              width="100%"
              height="100%"
            />
          </div>
          <div class="grou-sz text-center f-12 hang-jian-ju m-b-15">
            扫码关注小镇公众号<br />第一时间获得最新资讯
          </div>
          <div class="has-logo">
            <img
              :src="require('../assets/img/b-has-logo.png')"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="title">
        <div class="title-big text-center">
          <img
            :src="require('../assets/img/b-title.png')"
            width="100%"
            height="100%"
          />
        </div>
        <div class="text-center m-t-20">
          <span class="title-heading f-17 white">
            数字藏品盲盒限量首发预约开启
          </span>
        </div>
        <div class="brand white">
          <div class="f-13 m-b-10 brand-title">
            <span class="text-center">IP授权方</span>
            <span class="text-center p-l-30" style="box-sizing: border-box"
              >战略合作</span
            >
          </div>
          <div class="logo">
            <img
              :src="require('../assets/img/b-logo.png')"
              width="100%"
              height="100%"
            />
          </div>
          <div class="m-t-25 text-center f-15 white ju-zhong">
            <span style="opacity: 0.5">已预约人数</span>
            <span class="f-18">&nbsp;{{ total }}&nbsp;</span>
          </div>
          <div
            class="btn text-center f-25 bold white"
            @click="handleReserve"
            v-if="status === 1"
          >
            参与预约
          </div>
          <div
            class="btn text-center f-25 bold white"
            style="filter: brightness(0.5)"
            v-else
          >
            预约已结束
            <div class="yu-icon" style="filter: grayscale(1)">
              <img
                :src="require('../assets/img/b-yu-icon.png')"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div class="f-12 text-center" style="opacity: 0.3">
            预约后可点亮艾草参与提前购资格抽签<br />
            点亮艾草越多获得提前购资格概率越大
          </div>
        </div>
      </div>
      <div class="book">
        <div class="text-center f-16 ju-zhong" v-if="status === 1">
          距预约结束还有：{{ showTime2 }}
        </div>
        <div class="book-wrap m-t-25">
          <div class="book-wrap-left">
            <img
              :src="require('../assets/img/bencaigangmu.png')"
              width="100%"
              height="100%"
            />
          </div>
          <div class="book-wrap-right">
            中医药文化里程碑巨著<br />
            国家珍贵古籍文物<br />
            入选联合国世界记忆遗产名录
          </div>
        </div>
        <div class="text-center f-17 m-t-25">活动时间</div>
      </div>
      <div class="grass">
        <div class="time-step">
          <div class="bold f-15 m-b-5 text-right">{{ time1 }}</div>
          <div class="f-13 line text-right">开启预约和集草活动</div>
        </div>
        <div class="time-step">
          <div class="bold f-15 m-b-5">{{ time2 }}</div>
          <div class="f-13 line">集草活动停止</div>
        </div>
        <div class="time-step">
          <div class="bold f-15 m-b-5 text-right">{{ time3 }}</div>
          <div class="f-13 line text-right">公布集草中签结果</div>
        </div>
        <div class="time-step">
          <div class="bold f-15 m-b-5">{{ frontTime }}</div>
          <div class="f-13 line">优先抢购开启</div>
        </div>
        <div class="time-step">
          <div class="bold f-15 m-b-5 text-right">{{ time4 }}</div>
          <div class="f-13 line text-right">公开抢购开启</div>
        </div>
      </div>
      <div class="des">
        <div class="des-wrap">
          <div class="f-15 wrap-bg text-center des-wrap-title m-b-20 bold">
            我国首个中医药古籍数字藏品
          </div>
          <div class="f-12 hang-jian-ju m-b-25">
            《本草纲目》是李时珍耗尽三十年心血修撰的药物巨典。全书52卷、共190余万字，记载1892种药物，收纳11096个药方、1109幅药图，集明代以前本草学大成，且首次采用纲目体为药物分类，开创了历代本草著作之先河，在我国中医药文明发展中有着不可替代的独特贡献。
          </div>
          <div class="f-12 hang-jian-ju">
            本数字藏品是我国<span class="yellow bold"
              >首个中医药古籍数字藏品</span
            >
            ，堪称数字藏品发行里程碑，仅限量发行10000枚，永不增发。
          </div>
          <van-divider
            dashed
            :style="{ borderColor: '#907C5A', marginBottom: '25px' }"
          />
          <div class="f-15 wrap-bg text-center des-wrap-title m-b-20 bold">
            创新性采用用户共创铸造玩法
          </div>
          <div class="f-12 hang-jian-ju m-b-25">
            本次数字藏品铸造创新地采用“
            <span class="yellow bold"
              >用户参与、算法生成、反向铸造、完全随机</span
            >
            ”新玩法，分别发行“金木水火土”五种属性版本，每个版本各发行2000枚，通过盲盒形式随机分配属性。本次藏品用户需提供个人生辰信息参与藏品共创铸造，最终决定所获藏品“十二生肖”元素属性以及分配数量，
            <span class="yellow bold"
              >最终藏品稀缺性由参与用户共同铸造决定。</span
            >
          </div>
          <van-divider
            dashed
            :style="{ borderColor: '#907C5A', marginBottom: '25px' }"
          />
          <div class="f-15 wrap-bg text-center des-wrap-title m-b-20 bold">
            中医科学院信息所合作授权发布
          </div>
          <div class="f-12 hang-jian-ju m-b-25">
            中国中医科学院中医药信息研究所是中国唯一从事中医药信息研究与教学的部属研究院所，是文化部授予的“国家级古籍重点保护单位”、“全国中医行业古籍保护中心”、“国家级古籍修复中心”，现有馆藏中医古籍10万余册，其中包括中医古籍5589种，历代版本6556个，约占存世中医古籍品种的45%。
          </div>
          <div class="f-12 hang-jian-ju">
            本次发行的金陵版《本草纲目》为中医科学院信息所珍藏文物，已入选
            <span class="yellow bold"
              >第三批《国家珍贵古籍名录》和“联合国世界记忆遗产名录”</span
            >
            ，是具有纪念意义的珍贵文物遗存。
          </div>
          <van-divider
            dashed
            :style="{ borderColor: '#907C5A', marginBottom: '25px' }"
          />
          <div class="f-15 wrap-bg text-center des-wrap-title m-b-20 bold">
            源自家族自编版本高度体现原意
          </div>
          <div class="f-12 hang-jian-ju m-b-25">
            金陵版《本草纲目》是
            <span class="yellow bold"
              >目前流传于世的各版《本草纲目》的最早版本</span
            >
            ，
            也是迄今唯一完全由李时珍家族自编本刻版而成的版本，高度体现李时珍的原意。<br />
            经过400余年岁月洗礼，其内核历久弥新，依旧是新时代中医文化发展的重要思想源泉。
          </div>
          <van-divider
            dashed
            :style="{ borderColor: '#907C5A', marginBottom: '25px' }"
          />
          <div class="f-15 wrap-bg text-center des-wrap-title m-b-20 bold">
            领先的区块链技术保障安全可信
          </div>
          <div class="f-12 hang-jian-ju m-b-25">
            「本草纲目·金陵版」数字藏品基于头部互联网企业区块链技术解决方案发行，保障每个藏品在链上都拥有唯一标识，不可篡改，安全可靠，永久保存，上线后将开放限制性亲友转赠功能（详情请见活动规则）。
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="text-center m-b-25">
          <img
            :src="require('../assets/img/kefu.png')"
            width="100%"
            height="100%"
            class="group"
          />
        </div>
        <div class="tip text-center f-13">
          加入共创社群，第一时间了解小镇资讯
        </div>
      </div>
    </template>
    <tabbar :active="0"></tabbar>
    <van-overlay
      :show="isShowReserveSuccess"
      z-index="10"
      style="background-color: rgba(0, 0, 0, 0.8)"
      @click="isShowReserveSuccess = false"
    >
      <div class="wrapper" @click.stop>
        <div class="block reserve">
          <van-icon
            name="close"
            style="opacity: 0.8"
            @click="isShowReserveSuccess = false"
            class="white close"
            size="27"
          />
          <div class="text-center reserve-title bold m-b-10">
            恭喜您预约成功
          </div>
          <div class="f-17 text-center">获得一株艾草</div>
          <div class="reserve-des">
            <div class="reserve-des-wrap f-10 white">
              艾以叶入药，性温、味苦、无毒、纯阳之性、通十二经，具回阳、理气血、逐湿寒、止血安胎等功效。
              <div class="text-right f-10">——《本草纲目》</div>
            </div>
          </div>
          <div
            class="reserve-btn text-center f-20 m-t-20"
            @click="isShowReserveSuccess = false"
          >
            收下礼物
          </div>
          <div class="f-10 text-center" style="opacity: 0.3">
            拥有艾草越多，中签提前购资格概率越高
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 是否已经购买到本草纲目金陵版   -->
    <van-overlay
      :show="isShowGetBox"
      z-index="10"
      style="background-color: rgba(0, 0, 0, 0.8)"
      @click="isShowGetBox = false"
    >
      <div class="wrapper" @click.stop>
        <div class="block has-get-box">
          <van-icon
            name="close"
            style="opacity: 0.8"
            @click="isShowGetBox = false"
            class="white close"
            size="27"
          />
          <div class="text-center f-20 m-b-15">恭喜您，您已获得</div>
          <div class="text-center f-20 m-b-5 bold">本草纲目·金陵版</div>
          <div class="text-center f-20 m-b-15 bold">数字藏品盲盒</div>
          <div class="has-get-box-content">
            <div
              class="has-get-box-content-btn f-20 black text-center"
              @click="downApp"
            >
              立即下载
            </div>
            <div class="f-11 text-center m-b-5">
              请跳转下载APP在APP中查看盲盒
            </div>
            <div class="f-11 text-center">盲盒打开时间将后续公布</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--  查看  -->
    <van-overlay
      :show="isGrassOver"
      z-index="10"
      style="background-color: rgba(0, 0, 0, 0.8)"
      @click="isGrassOver = false"
    >
      <div class="wrapper" @click.stop>
        <div class="block has-get-box">
          <van-icon
            name="close"
            style="opacity: 0.8"
            @click="isGrassOver = false"
            class="white close"
            size="27"
          />
          <div class="text-center f-20 m-b-5">本草纲目集艾草活动已结束</div>
          <div class="text-center f-20 m-b-15">请下载APP查看艾草中签结果</div>
          <div class="has-get-box-content">
            <div
              class="has-get-box-content-btn f-20 black text-center"
              @click="downApp"
            >
              立即下载
            </div>
            <div class="f-11 text-center m-t-20">
              请跳转下载APP在APP中查看艾草中签结果
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <she-group-dlg
      :isShow="isShowGroup"
      @qr="qrcodeFN"
      @close="isShowGroup = false"
    ></she-group-dlg>
    <login
      :show="isShowLogin"
      @close="isShowLogin = false"
      @success="hasLogin"
    ></login>
  </div>
</template>

<script>
import { reserve } from "../api/benCao.js";
import Login from "../components/Login";
import SheGroupDlg from "../components/SheGroupDlg";
import tabbar from "../components/tabbar";
import axios from "axios";
import { mapMutations, mapState } from "vuex";
import { getWxConfig } from "../../tool";
import { userApi } from "../api/user";
import vueQr from "vue-qr";

const moment = require("moment");
const baseUrl = require("../../site");

export default {
  name: "BCGM",
  components: { Login, SheGroupDlg, tabbar, vueQr },
  data() {
    return {
      qrcode: "", //微信
      isShowReserveSuccess: false,
      isShowLogin: false,
      isShowGroup: false,
      isShowGetBox: false,
      isGrassOver: false,
      isJoin: false,
      total: 0,
      grass: 0,
      showTime: "00:00:00",
      showTime2: "00:00:00", // 预约结束倒计时
      showTime3: null, // 集艾草活动结束的倒计时
      buyTime: "00:00:00",
      status: 1, // 1.还在预约中 2.还未开始抢购 3.抢购中 4.抢购结束
      timer: null,
      timer1: null,
      time1: "", // 预约开始时间
      time2: "", // 结束预约时间
      time3: "", // 公布极草结果
      time4: "", // 正式抢购时间
      time55: null,
      time66: "",
      frontTime: "", // 优先抢购时间
      period: 1,
    };
  },
  created() {
    this.getReserve();
    this.getUser();
    // this.tipGrassResult()
    getWxConfig(
      "《本草纲目》金陵版数字藏品盲盒预约开启",
      "点击链接预约我国首个中医药文化古籍文物藏品，一起入驻轻松小镇～"
    );
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
    }),
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.time55);
  },
  methods: {
    ...mapMutations({
      vxSetUser: "setUser",
      vxSetSignIn: "setSignIn",
    }),
    downApp() {
      window.location.href = "http://app.relaverse.cn/m/download.html";
    },
    tipGrassResult() {
      if (!localStorage.getItem("hasGrassResult")) {
        this.isGrassOver = true;
        localStorage.setItem("hasGrassResult", true);
      }
    },
    async getReserve() {
      axios
        .post(baseUrl.domain2 + "index/mugwort/info/", {
          acctoken: localStorage.getItem("token"),
        })
        .then((success) => {
          const res = success.data;
          if (res.code == 200) {
            const now = new Date().getTime();
            const time1 = res.data.time1 * 1000; // 开始预约时间
            const time2 = res.data.time2 * 1000; // 集草停止时间
            const time3 = res.data.time3 * 1000; // 公布集草中签时间
            const time4 = res.data.time4 * 1000; // 开始抢购时间见
            const time5 = res.data.time5 * 1000; // 结束抢购时间
            const reserveEndTime = new Date("2022/03/16 13:00:00").getTime();
            this.time1 = moment(time1).format("MM-DD HH:mm");
            this.time2 = moment(time2).format("MM-DD HH:mm");
            this.time3 = moment(time3).format("MM-DD HH:mm");
            this.time4 = moment(time4).format("MM-DD HH:mm");
            this.frontTime = moment(res.data.ahead_time * 1000).format(
              "MM-DD HH:mm"
            );
            this.isJoin = res.data.join;
            this.total = res.data.count;
            this.grass = res.data.num;
            const second = new Date("2022/03/12 15:00:00").getTime();
            if (now < second) {
              this.period = 1;
            } else if (now >= second && now < time4) {
              this.period = 2;
            } else {
              this.period = 3;
            }
            // 结束预约倒计时
            this.timer1 = setInterval(() => {
              const time = this.countTime(reserveEndTime);
              if (time) {
                this.showTime2 = time;
              } else {
                this.showTime2 = "00:00:00";
              }
            }, 1000);
            // 艾草预约结束倒计时
            if (now < time2) {
              this.time55 = setInterval(() => {
                const time = this.countTime(time2);
                if (time) {
                  this.showTime3 = time;
                } else {
                  this.showTime3 = "00:00:00";
                }
              }, 1000);
            }
            if (now < time4) {
              this.status = 1;
              this.timer = setInterval(() => {
                const time = this.countTime(time4);
                if (time) {
                  this.showTime = time;
                } else {
                  clearInterval(this.timer);
                  this.showTime = "00:00:00";
                  this.timer = setInterval(() => {
                    const time = this.countTime(time5);
                    if (time) {
                      this.buyTime = time;
                    } else {
                      this.buyTime = "00:00:00";
                    }
                  }, 1000);
                  this.status = 3;
                }
              }, 1000);
            } else if (now >= time4 && now <= time5) {
              this.status = 3;
              this.timer = setInterval(() => {
                const time = this.countTime(time5);
                if (time) {
                  this.buyTime = time;
                } else {
                  this.buyTime = "00:00:00";
                }
              }, 1000);
            } else {
              this.status = 4;
            }
          } else {
            this.$toast(res.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast("服务器繁忙，请稍后再试");
        });
    },
    noBuy() {
      this.$toast("抢购还未开始");
    },
    buyIt() {
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      axios
        .post(baseUrl.domain2 + "index/mugwort/info/", {
          acctoken: localStorage.getItem("token"),
        })
        .then((success) => {
          const res = success.data;
          this.$toast.clear();
          if (res.code == 200) {
            if (res.data.url) {
              window.location.href = res.data.url;
            } else {
              this.$toast("暂无抢购地址");
            }
          } else {
            this.$toast(res.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.clear();
          this.$toast("服务器繁忙，请稍后再试");
        });
    },
    toPath() {
      window.location.href =
        "https://mp.weixin.qq.com/s/buk3ukGkMWzSwG7hql8XyA";
    },
    async handleReserve() {
      if (!this.isLogin) {
        this.isShowLogin = true;
        return;
      }
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      try {
        await reserve({
          mugwort_from_user: localStorage.getItem("invited_id"),
        });
        this.isShowReserveSuccess = true;
        this.isJoin = true;
        this.$toast.clear();
        this.getReserve();
      } catch (e) {
        console.log(e);
        this.$toast.clear();
        this.$toast(e.data);
      }
    },
    countTime(endTime) {
      const now = new Date().getTime();
      const duration = endTime - now;
      if (duration <= 0) {
        return false;
      }
      const day = Math.floor(duration / (1000 * 60 * 60 * 24));
      const hour = Math.floor((duration / (1000 * 60 * 60)) % 24);
      const min = Math.floor((duration / (1000 * 60)) % 60);
      const s = Math.floor((duration / 1000) % 60);
      return `${day ? day + "天" : ""}${hour < 10 ? "0" + hour : hour}:${
        min < 10 ? "0" + min : min
      }:${s < 10 ? "0" + s : s}`;
    },
    async getUser() {
      try {
        const res = await userApi({});
        if (res.data.mugwort_nft && !localStorage.getItem("hasBenCaiNFT")) {
          this.isShowGetBox = true;
          localStorage.setItem("hasBenCaiNFT", true);
        }
        this.vxSetUser({
          day: res.data.day,
          join_reserve: res.data.join_reserve,
          isSign: res.data.signs,
          ...res.data.user,
        });
      } catch (e) {
        console.log(e);
        if (e.code === 201) {
          this.isShowLogin = true;
        }
      }
    },
    qrcodeFN(v) {
      this.qrcode = v;
    },
    toPage(name) {
      this.$router.push({
        name,
      });
    },
    addGroup() {
      if (this.isLogin) {
        this.isShowGroup = true;
      } else {
        this.isShowLogin = true;
      }
    },
    hasLogin() {
      this.isShowLogin = false;
      this.getReserve();
    },
  },
};
</script>

<style scoped lang="scss">
.ben-cai-gan-mu {
  background-color: black;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .ju-zhong {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .head {
    height: 379px;
    background: url("../assets/img/b-h-bg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .zen-cai {
      position: absolute;
      width: 54px;
      height: 54px;
      top: 14px;
      right: 5px;
    }
    .rules {
      position: absolute;
      right: 0;
      top: 118px;
      width: 23px;
      padding: 5px 2px;
      background: rgba(255, 255, 255, 0.41);
      border: 1px solid white;
      border-right: none;
      box-shadow: 3px 8px 19px 1px rgba(43, 96, 128, 0.42);
      border-radius: 3px 0 0 3px;
    }
  }
  > .title {
    background: url("../assets/img/b-title-bg.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 30px;
    .title-big {
      .title-big-icon {
        width: 253px;
        height: 113px;
        position: relative;
        margin: auto;
        > .yu-icon {
          position: absolute;
          width: 51px;
          height: 44px;
          right: 25px;
          bottom: -15px;
        }
      }
      > img {
        width: 253px;
        height: 113px;
      }
    }
    .title-heading {
      background: url("../assets/img/b-heading-bg.png") no-repeat;
      background-size: 100% 100%;
      padding: 7px 15px;
      display: inline-block;
      letter-spacing: 3px;
    }
  }
  .z-brand {
    height: 280px;
    padding-top: 50px;
    background: url("../assets/img/b-book-bg.png") no-repeat;
    background-size: 100% 100%;
    margin-top: -40px;
    .ck_media {
      width: 318px;
      height: 60px;
      margin: 50px auto 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background: url("../assets/img/MTbdBG.png") no-repeat;
      background-size: 100% 100%;
      padding: 10px 0 5px;
      .tl_ck_media {
        font-size: 17px;
        font-weight: 400;
        color: #ffffff;
      }
      .lok_ck_media {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.5;
      }
    }
  }
  .has {
    padding: 0 12px 30px;
    margin-top: 20px;
    //margin-top: -30px;
    background: url("../assets/img/b-has-bg.jpg") no-repeat;
    background-size: 100% 100%;
    .has-one {
      background-size: 100% 100%;
      background-color: black;
      padding: 25px 19px;
      border-radius: 10px;
      border: 1px solid white;
      position: relative;
      overflow: hidden;
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .has-one-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .title-number {
        font-size: 37px;
      }
      .timeline {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
      .has-one-btn {
        width: 236px;
        margin: 40px auto 20px;
        height: 40px;
        line-height: 40px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0 0 20px -3px #cae0ff;
      }
      .has-one-des {
        line-height: 22px;
      }
    }
    .zhu {
      line-height: 18px;
    }
    .has-two {
      padding: 40px 15px 20px;
      background: url("../assets/img/b-has-two.png") no-repeat;
      background-size: 100% 100%;
      .has-two-title {
        background-image: radial-gradient(
          circle,
          rgba(12, 146, 184, 0.38),
          rgba(12, 146, 184, 0.38),
          rgba(12, 146, 184, 0)
        );
        padding: 3px;
      }
      .task {
        padding: 20px 7px;
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid #ffffff;
        border-radius: 7px;
        display: flex;
        align-items: center;
        .task-left {
          width: 83%;
        }
        .task-right {
          width: 17%;
          .task-right-btn {
            width: 50px;
            padding: 5px 0;
            background: rgba(255, 255, 255, 0.8);
            border: 1px solid #ffffff;
            box-shadow: 0px 0px 9px 0px #cae0ff;
            border-radius: 15px;
            display: inline-block;
          }
        }
      }
      .has-two-group {
        display: inline-block;
        padding: 5px 5px 0px;
        box-sizing: border-box;
        min-width: 107px;
        min-height: 107px;
        background: #ffffff;
        box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.45);
        border-radius: 7px;
      }
      .has-two-wrap {
        padding: 5px;
        margin: 20px auto 0;
        background-image: radial-gradient(
          circle,
          white,
          white,
          rgba(0, 0, 0, 0)
        );
        line-height: 17px;
      }
    }
    > .des {
      position: relative;
      z-index: 2;
      margin-top: 0;
      padding: 0;
      .wrap-bg {
        background-color: transparent; /* 浏览器不支持的时候显示 */
        background-image: radial-gradient(
          circle,
          #9b8662,
          #9b8662,
          rgba(255, 255, 255, 0)
        );
      }
      .yellow {
        color: #785a28;
      }
      .hang-jian-ju {
        line-height: 18px;
        padding: 0 10px;
      }
      .des-wrap {
        background: url("../assets/img/b-des-wrapbg.png") no-repeat;
        background-size: 100% 100%;
        padding: 33px 18px;
        border-radius: 10px;
        overflow: hidden;
        .des-wrap-title {
          padding: 5px;
          margin-left: 15px;
          margin-right: 15px;
        }
      }
    }
    .has-two-grou {
      display: inline-block;
      padding: 5px;
      box-sizing: border-box;
      width: 107px;
      height: 107px;
      background: #ffffff;
      box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.45);
      border-radius: 7px;
      margin-bottom: 10px;
    }
    .grou-sz {
      // opacity: 0.9;
    }
    .has-logo {
      width: 79px;
      height: 26px;
      margin: auto;
    }
  }
  .brand {
    margin-top: 30px;
    padding: 0 44px;
    .brand-title {
      > span {
        display: inline-block;
        width: 50%;
      }
    }
    .logo {
      height: 44px;
    }
  }
  .zheng-shi {
    margin-right: 44px !important;
    margin-left: 44px !important;
  }
  .btn {
    margin: 15px 0 28px;
    background: rgba(255, 255, 255, 0.23);
    border: 1px solid white;
    /*background-image: linear-gradient(173deg, #FFFFFF, #F9F6F7, #FFFFFF);*/
    border-radius: 28px;
    padding: 9px;
    position: relative;
    .yu-icon {
      position: absolute;
      width: 51px;
      height: 44px;
      right: 10px;
      bottom: -18px;
    }
  }
  .book {
    background: url("../assets/img/b-book-bg.png") no-repeat;
    background-size: 100% 100%;
    height: 293px;
    padding: 11px 0 0 0;
    .book-wrap {
      background: url("../assets/img/b-book-wrap.png") no-repeat;
      background-size: 100% 100%;
      height: 167px;
      padding: 32px 0 32px 75px;
      display: flex;
      align-items: center;
      .book-wrap-left {
        width: 33px;
        height: 101px;
        margin-right: 40px;
      }
      .book-wrap-right {
        line-height: 30px;
      }
    }
  }
  .grass {
    height: 520px;
    background: url("../assets/img/b-grass-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 30px 20px 0 10px;
    .time-step {
      width: 53%;
      padding-bottom: 7px;
      border-bottom: 1px solid white;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        background: #ffffff;
        box-shadow: 0 0 5px 0 #ffffff;
        border-radius: 50%;
        bottom: -4px;
        right: 0;
      }
      &:nth-child(1) {
        padding-right: 30px;
        width: 43%;
        margin-left: 10%;
      }
      &:nth-child(2) {
        margin-left: 52%;
        width: 49%;
        padding-left: 60px;
        &:after {
          left: 0;
        }
      }
      &:nth-child(3) {
        padding-right: 70px;
      }
      &:nth-child(4) {
        margin-left: 52%;
        width: 45%;
        padding-left: 70px;
        &:after {
          left: 0;
        }
      }
      &:nth-child(5) {
        padding-right: 70px;
        width: 45%;
        margin-left: 8%;
      }
    }
  }
  .des {
    padding: 17px;
    margin-top: -80px;
    position: relative;
    z-index: 2;
    .wrap-bg {
      background-color: transparent; /* 浏览器不支持的时候显示 */
      background-image: radial-gradient(
        circle,
        #9b8662,
        #9b8662,
        rgba(255, 255, 255, 0)
      );
    }
    .yellow {
      color: #785a28;
    }
    .hang-jian-ju {
      line-height: 18px;
      padding: 0 10px;
    }
    .des-wrap {
      background: url("../assets/img/b-des-wrapbg.png") no-repeat;
      background-size: 100% 100%;
      padding: 33px 18px;
      border-radius: 10px;
      overflow: hidden;
      .des-wrap-title {
        padding: 5px;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
  .footer {
    padding: 380px 0 0;
    margin-top: -350px;
    background: url("../assets/img/b-foot-bg.png") no-repeat;
    background-size: 100% 100%;
    height: 600px;
    .group {
      width: 118px;
      height: 118px;
      background: #ffffff;
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.45);
      border-radius: 7px;
      display: inline-block;
      padding: 7px;
    }
    .tip {
      width: 268px;
      height: 29px;
      line-height: 29px;
      background: rgba(255, 255, 255, 0.23);
      border: 1px solid white;
      background-image: linear-gradient(173deg, #ffffff, #f9f6f7, #ffffff) 1 1;
      border-radius: 15px;
      margin: 0 auto 25px;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 85%;
      position: relative;
      .close {
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .reserve {
      background: url("../assets/img/b-dlg-bg.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 13px;
      box-shadow: 0 0 21px 1px rgba(255, 255, 255, 0.35);
      padding: 25px 30px 15px;
      .reserve-title {
        font-size: 27px;
      }
      .reserve-des {
        background: #000000;
        opacity: 0.59;
        border-radius: 5px;
        padding: 3px;
        margin-top: 150px;
        .reserve-des-wrap {
          border: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 4px;
          padding: 10px 20px;
        }
      }
      .reserve-btn {
        background: rgba(255, 255, 255, 0.23);
        border: 1px solid;
        background-image: linear-gradient(173deg, #ffffff, #f9f6f7, #ffffff) 1 1;
        box-shadow: 0 0 18px -2px rgba(255, 255, 255, 0.8);
        border-radius: 25px;
        width: 180px;
        margin: 20px auto 10px;
        padding: 6px 10px;
      }
    }
    .has-get-box {
      .has-get-box-content {
        background: url("../assets/img/b-dlg-get-box.png") no-repeat;
        background-size: 100% 100%;
        border-radius: 13px;
        overflow: hidden;
        padding: 10px 30px 20px;
        .has-get-box-content-btn {
          margin: 297px auto 10px;
          width: 200px;
          background: #ffffff;
          border-radius: 21px;
          padding: 8px;
        }
      }
    }
  }
}
</style>
